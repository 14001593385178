.cheese-image {
  width: 25vw;
  max-width: 140px;
}

.cheese-image-animation-passive {
  animation-name: cheese-passive;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

.cheese-image-animation-working {
  animation-name: cheese-working;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes cheese-passive {
  0% {
    filter: saturate(100%);
  }
  100% {
    filter: saturate(200%);
  }
}

@keyframes cheese-working {
  0% {
    filter: initial;
  }
  100% {
    filter: brightness(130%) saturate(200%);
  }
}

@media only screen and (max-width: 580px) {
  .banner {
    /* no max-width */
    width: 90vw;
  }
}

.banner {
  max-width: 580px;
  width: 90vw;
}

.speech-bubble {
  position: relative;
  color: #f8f8f2;
  background-color: #23242f;
  padding: 1.125em 1.5em;
  border-radius: 1rem;
  border: 3px solid #4c5067;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
}

.speech-bubble::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: calc(50% - 0.75rem);
  top: -15px;
  border: 0.75rem solid transparent;
  border-top: none;

  border-bottom-color: #febf15;
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}
